<template>
    <footer class="footer">
        <a href="https://www.linkedin.com/in/miriam-siegenthaler-371235132/" target="_blank" class="social">
            <i class="fab fa-linkedin-in" aria-hidden="true"></i>
            LinkedIn
        </a>
        <a href="https://www.instagram.com/miriamsiegenthaler/" target="_blank" class="social">
            <i class="fab fa-instagram" aria-hidden="true"></i>
            instagram
        </a>
        <a href="https://www.facebook.com/Talentierbar-112803193820886" target="_blank" class="social">
            <i class="fab fa-facebook" aria-hidden="true"></i>
            facebook
        </a>
    </footer>
</template>

<script>
export default {
    name: "ChatFooter"
}
</script>

<style scoped>
.footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-basis: 50px;
}

.footer .social {
    color: white;
}

@media(max-width:1024px) {
    .footer {
        display: none;
    }
}

</style>