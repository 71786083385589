module.exports = {
    firstInterview: [
        {
            messages: [
                'Hallo ich bin die virtuelle Vetretung von Miriam',
                'Schön, dass du dir 15-20 Minuten Zeit nimmst für dich selbst',
                'Gerne stelle ich dir ein paar Fragen',
                'Deine Antworten werden nach dem Gespräch wieder gelöscht und nur du kannst sie sehen',
                'Sag mal, wie darf ich dich nennen?',
            ],
            input: {
                type: 'variable',
                variableName: 'name',
            }
        },
        {
            messages: [
                'Wunderbar %name%, es freut mich sehr, dich kennenzulernen',
                'Bist du bereit?'
            ],
            input: {
                type: 'choice',
                choices: [
                    { message: 'Ja, los gehts!', key: 'go' },
                ],
            },
        },
        {
            messages: [
                'Zum Einstieg möchte ich etwas mehr über dich erfahren',
                'Also, %name%, was machst du gerne?',
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 1,
                    questions: [
                        'Toll! Und was machst du sonst noch gerne?'
                    ],
                },
            },
        },
        {
            messages: [
                'Und sag mal, was gelingt dir besonders gut?'
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Spannend!',
                'Und was sagen andere, dir wichtige Personen, was du gut kannst?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Was ist dir wichtig im Leben?',
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 1,
                },
            },
        },
        {
            messages: [
                'Vielen Dank für deine Offenheit',
                'Sag mal %name%, woran wirst du merken, dass diese Unterhaltung für dich hilfreich war?',
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 1,
                    questions: [
                        'Woran noch?'
                    ],
                },
            },
        },
        {
            messages: [
                'Welchen Unterschied macht das für dich?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Was werden die positiven wirkungen davon sein?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Woran werden andere merken, dass die Unterhaltung hilfreich war?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Angenommen du hättest zu einem bestimmten Zeitpunkt dein Ziel vollständig erreicht, was machst du dann anders, was du jetzt noch nicht machst?',
                'Beschreibe bitte möglichst genau, was du dann anders machst',
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 2,
                    questions: [
                        'Und was noch?',
                        'Was machst du sonst noch anders?'
                    ]
                },
            },
        },
        {
            messages: [
                'Was sind die positiven Wirkungen davon?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Wer oder was würde sonst noch bemerken, dass du dein Ziel vollständig erreicht hast? Woran?',
            ],
            input: {
                type: 'text',
                whatElse: true,
            },
        },
        {
            messages: [
                'Welche Teile dieser Zukunft fanden in der näheren Vergangenheit bereits statt?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Wie hast du das geschafft, %name%?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Wie haben andere dazu beigetragen?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Was wird nach unserer Unterhaltung ein erstes kleines Zeichen sein, an dem du erkennst, dass du begonnen hast, Fortschritte zu machen?',
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 1,
                    questions: [
                        'Woran noch?',
                    ],
                },
            },
        },
        {
            messages: [
                'Was machst du dann, was du bisher noch nicht gemacht hast?',
                'Bitte beschreibe möglichst detailliert'
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 1,
                },
            },
        },
        {
            messages: [
                'Wer oder was könnte deine Zuversicht steigern, dass dir das gelingt?',
            ],
            input: {
                type: 'text',
                whatElse: {
                    count: 1,
                    questions: ['Wer oder was noch?'],
                },
            },
        },
        {
            messages: [
                '%name%, wir kommen langsam zum Abschluss',
                'Was war für dich das relevanteste, was du in dieser Unterhaltung entdeckt hast?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Gibt es etwas, was du zum Abschluss noch umbedingt sagen möchtest?',
            ],
            input: {
                type: 'text',
            },
        },
        {
            messages: [
                'Vielen Dank für deine Zeit %name%',
                'Inwiefern war diese Unterhaltung hilfreich für dich?',
            ],
            input: {
                type: 'scale',
            },
        },
        {
            messages: [
                'Diese Fragen beruhen auf der Haltung der Lösungsfokussierung nach der Schule von Milwauke/Winsconsin, nach welcher ich arbeite',
                'Im persönlichen Gespräch stelle ich dir zu deinem Anliegen gerne weitere Fragen, die noch mehr in die Tiefe gehen und optimal auf dein Anliegen zugeschnitten sind',
            ],
            input: {
                type: 'none',
            },
        },
    ],
}
