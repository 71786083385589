<template>
    <div class="chat-wrapper js-fullheight">
        <chat-header/>
        <div class="chat-window" ref="chat">
            <div v-if="chatType" class="chat">
                <div
                    v-for="(message, idx) in messages"
                    :key="idx"
                    :class="{'bubble': true, me: message.isMe }">
                    <div class="text">{{ message.text }}</div>
                    <div class="timestamp">{{ timestampFmt(message) }}</div>
                </div>

                <div v-if="finished" class="button-wrapper">
                    <a class="button" target="_blank" href="https://miriam-siegenthaler.ch/kommunikationscoaching/">
                        Jetzt Gespräch vereinbaren
                    </a>
                </div>

                <div
                    v-show="typingInProgress"
                    class="bubble"
                >
                    <loading-dots/>
                </div>
            </div>
            <div v-else class="chat-type">
                <div class="explanation">
                    Willkommen beim VCoach
                    <br/>
                    <br/>
                    Teste eine kostenlose, lösungsfokussierte Unterhaltung mit meiner virtuellen Vertretung
                </div>
                <div class="type" v-on:click="chooseType('firstInterview')">
                    <div class="title">Ausführliche Version</div>
                    <time>ca. 15-20 Minuten</time>
                </div>
                <div v-if="false" class="type" v-on:click="chooseType('shortFirstInterview')">
                    <div class="title">Kurze Version</div>
                    <time>ca. 5 Minuten</time>
                </div>
            </div>
        </div>

        <div class="interactive" v-if="!finished">
            <template v-if="inputOptions && !inputDisabled">
                <div v-if="inputOptions.type === 'scale'">
                    <input-scale :name="variables.name" @inputSelected="processInput"/>
                </div>
                <div v-if="inputOptions.type === 'choice'">
                    <input-choice :options="inputOptions.choices" @inputSelected="processInput"/>
                </div>
                <div class="text" v-if="inputOptions.type === 'text' || inputOptions.type === 'variable'">
                    <input
                        ref="textinput"
                        class="textinput"
                        autofocus="autofocus"
                        v-model="currentInput"
                        v-on:keyup="keyUp"
                        placeholder="Antwort hier eintippen"
                        :disabled="!(inputOptions.type === 'text' || inputOptions.type === 'variable')"
                    />
                    <button
                        class="send"
                        v-on:click="sendMessage"
                        :disabled="(inputOptions.type === 'text' || inputOptions.type === 'variable') && !canSend"
                    >
                        <i class="fas fa-paper-plane"></i>
                    </button>
                </div>
            </template>
        </div>
        <chat-footer/>
    </div>
</template>

<script>
import ChatHeader from "@/components/ChatHeader";
import LoadingDots from "@/components/LoadingDots";
import ChatFooter from "@/components/ChatFooter";
import InputChoice from "@/components/InputChoice";
import InputScale from "@/components/InputScale";

const messageTree = require('./message-tree');
const moment = require('moment');

export default {
    name: "ChatWindow",
    components: {
        InputScale,
        InputChoice,
        ChatFooter,
        LoadingDots,
        ChatHeader
    },
    data() {
        return {
            finished: false,
            chatType: null,
            whatElseCount: 0,
            currentInput: '',
            messages: [],
            variables: {
                name: null,
            },
            messageTreeIndex: null,
            typing: false,
            typingInProgress: false,
        };
    },
    mounted() {
        window.addEventListener('resize', this.resize);
    },
    computed: {
        currentMessageTree() {
            if (!this.chatType) {
                return null;
            }

            return messageTree[this.chatType];
        },
        inputDisabled() {
            return this.typing;
        },
        canSend() {
            return !!this.currentInput.replace(/\s/g, '').length;
        },
        inputOptions() {
            if (this.chatType === null) {
                return null;
            }

            if (this.messageTreeIndex === null) {
                return null;
            }

            return this.currentMessageTree[this.messageTreeIndex].input;
        }
    },
    methods: {
        resize() {
            this.scrollToBottom();
        },
        chooseType(type) {
            this.chatType = type;

            setTimeout(this.next, 500);
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const el = this.$refs.chat;

                if (!el) {
                    return;
                }

                el.scrollTop = el.scrollHeight;
            });
        },
        keyUp(ev) {
            if (ev.keyCode === 13) {
                this.sendMessage();
            }
        },
        timestampFmt(message) {
            const ts = moment(message.timestamp);

            return ts.format('HH:mm');
        },
        processInput(input) {
            const message = input.message;

            switch (this.inputOptions.type) {
                case 'variable':
                    this.variables[this.inputOptions.variableName] = message;
                    break;
                case 'choice':
                    this.addMessage(false, message);
                    break;
                case 'scale':
                    this.addMessage(false, message);
                    break;
            }

            if (this.inputOptions.whatElse) {
                if (this.whatElseCount < this.inputOptions.whatElse.count) {
                    const whatElseQuestion = this.inputOptions.whatElse.questions
                        ? this.inputOptions.whatElse.questions[this.whatElseCount]
                        : 'Was noch?';

                    this.addBotMessages([whatElseQuestion]);

                    this.whatElseCount++;

                    return;
                }

                this.whatElseCount = 0;
            }

            this.next();
        },
        sendMessage() {
            if (!this.canSend) {
                return;
            }

            this.addMessage(false, this.currentInput);
            this.processInput({ message: this.currentInput });

            this.currentInput = '';
        },
        async waitFor(nMilliseconds) {
            return new Promise((resolve) => {
                setTimeout(resolve, nMilliseconds);
            })
        },
        async addBotMessages(messages) {
            this.typing = true;

            for (const message of messages) {
                const processedMessage = message.replaceAll('%name%', this.variables.name);

                if (this.typingInProgress) {
                    this.typingInProgress = false;
                    await this.waitFor(400);
                }

                this.typingInProgress = true;
                await this.waitFor(10);
                this.scrollToBottom();
                await this.waitFor(200 + processedMessage.length * 50);

                this.addMessage(true, processedMessage);
            }

            this.typing = false;
            this.typingInProgress = false;

            this.$nextTick(() => {
                const input = this.$refs.textinput;

                if (!input) {
                    return;
                }

                input.focus();
            });
        },
        addMessage(asBot, message) {
            this.messages.push({
                text: message,
                timestamp: new Date(),
                isMe: !asBot
            });

            this.scrollToBottom();
        },
        async next() {
            if (this.chatType === null) {
                return;
            }

            if (this.messageTreeIndex === null) {
                this.messageTreeIndex = 0;
            } else {
                this.messageTreeIndex++;
            }

            if (this.messageTreeIndex >= this.currentMessageTree.length) {
                return;
            }

            const currentItem = this.currentMessageTree[this.messageTreeIndex];

            await this.addBotMessages(currentItem.messages);

            if (this.messageTreeIndex >= this.currentMessageTree.length - 1) {
                this.finished = true;

                await this.waitFor(10);

                this.scrollToBottom();
            }
        },
    }
}
</script>

<style scoped>
.chat-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;

    background-color: #566351;
}

.chat-window {
    width: 100%;
    flex: 1 1;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    background-color: #e1e1e1;
}

.chat-type {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chat-type .explanation {
    font-size: 20px;
    padding: 30px 20%;
}

.chat-type .type {
    cursor: pointer;
    padding: 30px;
    margin: 10px;
    border-radius: 15px;
    background-color: white;

    box-shadow: 0 1px 1px rgba(0, 0, 0, .13);

    transition: box-shadow 250ms ease-in-out;
}

.chat-type .type .title {
    font-size: 25px;
    margin-bottom: 7px;
}

.chat-type .type time {
    color: #a2a2a2;
}

.chat-type .type:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, .23);
}

.chat {
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    flex-grow: 1;
}

.bubble {
    background-color: white;
    align-self: flex-start;
    text-align: left;
    max-width: 80%;

    padding: 5px 10px;
    border-radius: 5px;
    margin: 6px 2px;

    box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.bubble .timestamp {
    font-size: 75%;
    color: #a2a2a2;
    text-align: left;
    margin-top: 3px;
}

.bubble.me {
    background-color: #DCF8C6;
    align-self: flex-end;
}

.bubble.me .timestamp {
    text-align: right;
}

.button-wrapper {
    padding-top: 20px;
}

.button-wrapper .button {
    display: inline-block;
    background-color: #566351;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
}

.interactive {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 14px;
    font-size: 16px;
    min-height: 59px;

    background-color: #566351;
}

.interactive .text {
    display: flex;
}

.interactive .textinput {
    flex-grow: 1;
    outline: none;
    border: 0;
    border-radius: 7px;
    padding: 10px 20px;
    font-size: inherit;
}

.interactive .send {
    background-color: transparent;
    color: white;
    padding: 0 10px;
    border: 0;
    outline: none;
    font-size: 150%;
}
</style>