<template>
    <div class="scale-items-wrapper">
        <div class="scale-items">
            <div class="label-left">
                wenig hilfreich
            </div>

            <div class="label-right">
                äusserst hilfreich
            </div>

            <div v-for="item in items"
                 :key="item"
                 class="item"
                 @click="selectInput(item)"
            >
                {{ item }}
            </div>
        </div>
        <div class="hint">
            <a class="button" @click="selectInput(null)">
                <i class="fas fa-comment-slash"></i> Möchte ich nicht angeben
            </a>
            <div>
                <i class="fas fa-info-circle"></i> Diese Antwort wird übertragen
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputScale",
    props: {
        name: {}
    },
    data() {
        return {
            items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        };
    },
    methods: {
        selectInput(value) {
            if (value === null) {
                this.$emit('inputSelected', { message: 'Möchte ich nicht angeben' });
                return;
            }

            const data = new URLSearchParams();
            data.append('scale', 'VCoach: ' + this.name + ' hat ' + value + '/10 geantwortet.');
            data.append('link', 'https://vcoach.miriam-siegenthaler.ch/');

            fetch('https://iyfua4svkk.execute-api.eu-west-1.amazonaws.com/', {
                method: "post",
                body: data
            });

            this.$emit('inputSelected', { message: value + ' von 10' });
        },
    },
}
</script>

<style scoped>
.scale-items-wrapper {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.scale-items-wrapper .hint {
    padding-top: 10px;
}

.hint .button {
    padding-bottom: 10px;
    display: inline-block;
    cursor: pointer;
    color: #a2a2a2;

    transition: color 250ms ease-in-out;
}

.hint .button:hover {
    color: white;
}

.scale-items {
    padding-top: 30px;
    padding-bottom: 5px;
    position: relative;
    display: flex;
    justify-content: center;
}

.scale-items .item {
    color: black;
    padding: 5px 10px;
    background-color: white;
    margin: 0 4px;
    transition: background-color 250ms ease-in-out;
    cursor: pointer;
}

.scale-items .item:hover {
    background-color: #c4ccc1;
}

.label-left, .label-right {
    position: absolute;
    color: white;
}

.label-left {
    left: 0;
    top: 0;
}

.label-right {
    right: 0;
    top: 0;
}
</style>