<template>
    <div id="app">
        <Psycho/>
    </div>
</template>

<script>
import Psycho from './components/Psycho.vue'

export default {
    name: 'App',
    components: {
        Psycho
    }
}
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: black;
    height: 100%;
}

html, body {
    height: 100%;
    padding: 0;
    margin: 0;

    font-family: Raleway, sans-serif;
}

* {
    font-family: Raleway, sans-serif;

    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4 {
    font-family: Poppins,sans-serif;
    font-weight: 700;
}

a, a:visited, a:hover {
    color: inherit;
    text-decoration: none;
}
</style>
