<template>
    <div class="loading-dots">
        <span></span>
        <span></span>
        <span></span>
    </div>
</template>

<script>
export default {
    name: "LoadingDots"
}
</script>

<style scoped>
.loading-dots {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 1.5em;
    padding: 4px;
}

.loading-dots span {
    width: 5px;
    height: 5px;
    background: #22303e;
    display: inline-block;
    border-radius: 50%;

    margin-right: 5px;
    position: relative;
    animation: jump 1s infinite;
}

.loading-dots span:nth-child(2) {
    animation-delay: 100ms;
}

.loading-dots span:nth-child(3) {
    animation-delay: 200ms;
    margin-right: 0;
}

@keyframes jump {
    0%   {bottom: 0;}
    20%  {bottom: 5px;}
    40%  {bottom: 0;}
}
</style>