<template>
    <div class="choices">
        <div v-for="(choice, idx) in options" :key="idx" class="choice"
             v-on:click="$emit('inputSelected', choice)">{{ choice.message }}
        </div>
    </div>
</template>

<script>
export default {
    name: "InputChoice",
    props: {
        options: {},
    },
}
</script>

<style scoped>
.choices {
    display: flex;
    justify-content: center;
}

.choice {
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
}
</style>