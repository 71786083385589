<template>
    <div class="chat-header">
        <div class="profile-image"></div>
        <div class="name">
            Miriam Siegenthaler
            <div class="mobile-smaller">Kommunikation und Gesprächsführung</div>
        </div>
        <div class="burger" v-on:click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="overlay js-fullheight" :class="{ cover: menuOpen }">
            <div class="close" v-on:click="toggleMenu">
                <span></span>
                <span></span>
            </div>

            <div class="header-social">
                <a href="https://www.linkedin.com/in/miriam-siegenthaler-371235132/" target="_blank" class="social">
                    <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                    LinkedIn
                </a>
                <a href="https://www.instagram.com/miriamsiegenthaler/" target="_blank" class="social">
                    <i class="fab fa-instagram" aria-hidden="true"></i>
                    instagram
                </a>
                <a href="https://www.facebook.com/Talentierbar-112803193820886" target="_blank" class="social">
                    <i class="fab fa-facebook" aria-hidden="true"></i>
                    facebook
                </a>
            </div>

            <nav class="navigation">
                <a class="nav-item-image" href="/"></a>
                <a class="nav-item" href="/">Neustart</a>
                <a class="nav-item" target="_blank" href="https://miriam-siegenthaler.ch">Zur Webseite</a>
                <a class="nav-item" target="_blank" href="https://miriam-siegenthaler.ch/coaching">Gespräch vereinbaren</a>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatHeader",
    data() {
        return {
            menuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
    },
}
</script>

<style scoped>
.chat-header {
    color: white;
    flex: 0 0;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D1D7DB;
}

.chat-header .profile-image {
    background-image: url("../assets/img/cartoon-miri-small.jpg");
    box-shadow: 0 0 2px 1px rgba(255, 255, 255, 1);
    background-size: cover;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    flex-shrink: 0;
}

.chat-header .name {
    text-align: left;
    font-size: 18px;
    flex-grow: 1;
}

.burger {
    margin-left: 20px;
    width: 20px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    flex-shrink: 0;
}

.burger span {
    height: 2px;
    width: 100%;
    background-color: white;
    display: inline-block;
}

.overlay {
    z-index: 999;

    display: flex;
    position: fixed;
    top: 0;
    left: 0;

    transform: translateX(200%);

    width: 50vw;
    height: 100vh;

    background-color: #566351;

    transition: transform 500ms ease-in-out;

    justify-content: center;
    align-items: center;
}

.overlay.cover {
    transform: translateX(100%);
}


@media(max-width:1024px) {
    .overlay {
        transform: translateX(100%);

        width: 100vw;
    }

    .overlay.cover {
        transform: translateX(0);
    }
}


.overlay .navigation {
    display: flex;
    font-weight: 300;
    flex-direction: column;
}

.overlay .nav-item {
    color: white;
    padding: 6px 12px;
    font-size: 23px;
    text-align: center;
}

.overlay .nav-item-image {
    display: inline-block;
    width: 24vh;
    height: 30vh;
    max-width: 274px;
    max-height: 316px;
    margin-bottom: -20px;
    background-image: url("../assets/img/logo-white-min-alt.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.overlay .header-social {
    position: absolute;
    bottom: 30px;

    color: white;
    display: flex;
    justify-content: space-around;
    width: 80%;
    flex-grow: .5;
    align-items: flex-end;
}


.close {
    position: absolute;
    right: 20px;
    top: 25px;
    width: 24px;
    height: 20px;
    cursor: pointer;
}

.close span {
    background-color: white;
    height: 2px;
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 50%;

    transform: translateX(-50%) rotate(45deg);
}

.close span:nth-child(2) {
    transform: translateX(-50%) rotate(-45deg);
}


.mobile-smaller {
    font-size: 90%;
}

@media(max-width:512px) {
    .mobile-smaller {
        font-size: 70%;
    }
}


</style>